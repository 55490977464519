'use client';

import { formatDateTimeLong, formatDateTimeShort } from '@streetferret/utils';
import { useEffect, useState } from 'react';

interface DateTimeProps {
  date?: Date;
  className?: string;
  /**
   * Show the timezone
   */
  withTz?: boolean;
  /**
   * Only show the date, not the time
   */
  hideTime?: boolean;
  /**
   * Use shorter date format
   */
  short?: boolean;
}
export function DateTime({
  date,
  className,
  withTz,
  hideTime,
  short,
}: DateTimeProps) {
  const [locale, setLocale] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    setLocale([...navigator.languages]);
  }, []);

  return (
    <span className={className}>
      {date
        ? (short ? formatDateTimeShort : formatDateTimeLong)(date, {
            tz: withTz,
            hideTime,
            locale,
          })
        : null}
    </span>
  );
}
